import { ConfigurationSetting, FeatureFlagValue } from "@azure/app-configuration";

import { EnvNames } from "~/constants/env-names";

type Config<T = unknown> = Record<string, T>;

export type Dictionary<T = unknown> = Record<string, T> & {
  default?: T;
};

export interface Protected<ST = unknown, CT = ST> {
  server?: ST;
  client?: CT;
}

export enum NewRelicEnvs {
  dev = "dev",
  prod = "prod",
}

export interface FeatureFlagList {
  bloomreachBruid?: FeatureFlagValue;
  bloomreachRefUrl?: FeatureFlagValue;
  changeInfoProfile?: FeatureFlagValue;
  shoppingList?: FeatureFlagValue;
  clickAndCollect?: FeatureFlagValue;
  newsletterSignUp?: FeatureFlagValue;
  preferredStore?: FeatureFlagValue;
  doubleOptIn?: FeatureFlagValue;
  myAccount?: FeatureFlagValue;
  contactUsPage?: FeatureFlagValue;
  pricePerUnit?: FeatureFlagValue;
  PDPLongDescription?: FeatureFlagValue;
  newCategoryFormat?: FeatureFlagValue;
  dynamicYield?: FeatureFlagValue;
  displayRegion?: FeatureFlagValue;
  recentlyViewedProducts?: FeatureFlagValue;
  customLocationSearch?: FeatureFlagValue;
  loqateLocationSearch?: FeatureFlagValue;
  geolocation?: FeatureFlagValue;
  abandonedCart?: FeatureFlagValue;
  ugcWidgetPdp?: FeatureFlagValue;
  olapicCheckout?: FeatureFlagValue;
  miniAddToBagCheckoutAndViewBagCTA?: FeatureFlagValue;
  dyCampaignABMiniAddToBagCheckoutAndViewbagCTA?: FeatureFlagValue;
  applePayQuickBuy?: FeatureFlagValue;
  dyCampaignABApplePayExpressCheckout?: FeatureFlagValue;
  isDynamicSwiperExpressCheckout?: FeatureFlagValue;
  productDataSheetPage?: FeatureFlagValue;
  crossSellFeatureBasketQuickView?: FeatureFlagValue;

  //DY campaigns
  dyCampaignPoc?: FeatureFlagValue;
  dyCampaignRVP?: FeatureFlagValue;
  dyCampaignUgcPdp?: FeatureFlagValue;
  dyCampaignLoq?: FeatureFlagValue;
  dyCampaignGeo?: FeatureFlagValue;
  dyCampaignSplitterHome?: FeatureFlagValue;
}

export interface WithFeatureFlags {
  featureFlagList?: FeatureFlagList;
}

export interface ClientConfig extends Config {
  envName?: EnvNames;
  testKey?: string;
  technical?: {
    debug?: string;
    testIdsAreEnabled?: string;
    minifyJsIsEnabled?: string;
    minifyCssIsEnabled?: string;
  };
  azure?: {
    adB2C?: {
      clientId?: string;
      tenant?: string;
      signUpSignInFlow?: string;
      changePasswordFlow?: string;
      resetPasswordFlow?: string;
      customDomains?: {
        signUpSignIn?: string;
        changePassword?: string;
        resetPassword?: string;
      };
    };
  };
  gtm?: {
    id?: string;
  };
  oneTrust?: {
    id?: string;
  };
  facebookAppId?: string;
  bloomReachPixel?: {
    domainKey?: string;
    id?: string;
    testData?: string;
    tms?: string;
    crossSellWidget?: {
      id?: string;
    };
    catalogName?: string;
  };
  bloomReach?: {
    recentlyViewedWidget?: {
      id?: string;
      brApiUrl?: string;
      maximumProductsInCarousel?: number;
      minimumProductsInCarousel?: number;
    };
  };
  evergage?: {
    anonymousUserId?: string;
    cookieDomain?: string;
    snippetUrl?: string;
  };
  loqate?: {
    clientId?: string;
  };
  socialNativeWidget?: {
    apiKey?: string;
    script?: string;
  };
  salesforceForm?: {
    parameters?: string;
    preferredStoreId?: string;
    accountCreationLeadParameters?: string;
  };
  gaTrackingId?: string;
  newRelic?: {
    env?: NewRelicEnvs;
  };
  amplience?: {
    robotsTxtDeliveryKey?: string;
    globalLayoutAndNavigationTTLSeconds: string;
  };
  apiBaseUrl?: string;
  expressCheckoutApiUrl?: string;
  expressCheckoutKountClientID?: string;
  expressCheckoutKountEnv?: string;
  expressCheckoutKountIsDebugEnable?: string;
  frontEndBaseUrl?: string;
  cookieDomain?: string;
  contactUsForm?: {
    parameters?: string;
  };
  thanksForYourOrderPage?: {
    timeToExpire?: string;
  };
  resolverVersion?: string;
  dynamicYield?: {
    id?: string;
    clientApiKey?: string;
    euClientBaseUrl?: string;
    euClientReportCollectUrl?: string;
    engagementEuClientBaseUrl?: string;
  };
  storeSearch?: {
    storeSearchDelay?: string;
    storeSearchType?: string;
    loqateSearchUrl?: string;
    loqateLocationTypes?: string;
  };
  googleMaps?: {
    apiKey?: string;
    searchWaitingTime?: number;
  };
  exceptionalLinks?: string;
  earlyHints?: {
    thirdPartyLinks?: string;
  };
}

export interface ServerOnlyConfig extends Config {
  testKey?: string;
  apiBaseUrl?: string;
  configTtl?: number;
}

export type ClientAvailableConfigData = ClientConfig & WithFeatureFlags;

export type AppConfig = ClientConfig & ServerOnlyConfig;

export type AllPossibleConfigData = AppConfig & WithFeatureFlags;

export enum AzureConfiguratorEvent {
  INIT = "INIT",
  UPDATE = "UPDATE",
  SET = "SET",
}

export type ListConfigurationSettingsType = (params: { keyFilter: string }) => {
  next: () => Promise<IteratorResult<ConfigurationSetting>>;
  [Symbol.asyncIterator]: () => unknown;
};

export interface AzureConfigurationClientInterface {
  listConfigurationSettings: ListConfigurationSettingsType;
}

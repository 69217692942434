import EventEmitter from "events";

import {
  AppConfigurationClient,
  ConfigurationSetting,
  parseFeatureFlag,
} from "@azure/app-configuration";
import {
  defaults,
  defaultsDeep,
  memoize,
  set,
  get,
  isEmpty,
  forEach,
  toNumber,
  has,
} from "lodash";

import { EnvVars } from "~/constants/env-vars";
import { Nullable, Optional } from "~/types/general.types";

import { getConfig } from "../config-storage/get-config";
import { setConfig } from "../config-storage/set-config";

import {
  clientSidePrefix,
  AZURE_CONFIG_DEFAULT_LOCALE,
  DEFAULT_TTL,
  featureFlagPrefix,
  featureFlagRegExp,
  LOGGER_CONTEXT,
  serverSidePrefix,
  webAppPrefix,
  webAppRegExp,
} from "./constants";
import { getEnvironmentVariable, getOverridesForLocalDevelopment } from "./helpers";
import {
  AllPossibleConfigData,
  AppConfig,
  AzureConfigurationClientInterface,
  AzureConfiguratorEvent,
  ClientConfig,
  Dictionary,
  FeatureFlagList,
  Protected,
  ServerOnlyConfig,
} from "./types";

const isLocal = () =>
  getEnvironmentVariable(EnvVars.NEXT_PUBLIC_LOCAL_DEV)?.toLowerCase() === "true";

export class AzureConfigurator extends EventEmitter {
  static readonly event = AzureConfiguratorEvent;
  static instance = new AzureConfigurator();

  /** Only for local development, not supposed to be pushed */
  private static mockedConfigValues: Nullable<AppConfig>;
  private static mockedFlagValues: Nullable<FeatureFlagList>;

  private static initPromise: Promise<void>;

  private static get clientCache() {
    return get(getConfig(), ["publicRuntimeConfig", "azureConfig"]);
  }

  private static get serverCache() {
    return get(getConfig(), ["serverRuntimeConfig", "azureConfig"]);
  }

  private static get lastUpdate(): Optional<number> {
    return AzureConfigurator.clientCache?.timestamp;
  }

  static get isInitialized(): boolean {
    return !!AzureConfigurator.instance.client;
  }

  static get isReceived(): boolean {
    return !!AzureConfigurator.lastUpdate;
  }

  private static get configTtl() {
    return toNumber(AzureConfigurator.getConfig()?.configTtl) || DEFAULT_TTL;
  }

  private static getMemoizedByLocaleParamsFunction = <ReturnType>(
    func: (locale?: string) => ReturnType,
  ) =>
    memoize((locale?: string) => {
      const memoByTime = memoize(
        () => {
          typeof memoByTime.cache.clear === "function" && memoByTime.cache.clear();
          return memoize(
            () => func(locale),
            () => IS_SERVER,
          );
        },
        () => AzureConfigurator.lastUpdate,
      );
      return memoByTime;
    });

  private static getFeatureFlagsMemo =
    AzureConfigurator.getMemoizedByLocaleParamsFunction<FeatureFlagList>(
      (locale?: string) => {
        return defaults(
          {},
          locale ? AzureConfigurator.clientCache?.[locale]?.featureFlagList : {},
          AzureConfigurator.clientCache?.default?.featureFlagList,
        );
      },
    );

  private static getAppConfigMemo =
    AzureConfigurator.getMemoizedByLocaleParamsFunction<AllPossibleConfigData>(
      (locale?: string) => {
        const localOverrides = isLocal() ? getOverridesForLocalDevelopment() : {};

        const publicPart = defaultsDeep(
          {},
          locale ? AzureConfigurator.clientCache?.[locale] : {},
          AzureConfigurator.clientCache?.default,
          localOverrides,
        );

        const featureFlagList = defaults(
          {},
          locale ? AzureConfigurator.clientCache?.[locale]?.featureFlagList : {},
          AzureConfigurator.clientCache?.default?.featureFlagList,
        );
        if (!isEmpty(featureFlagList)) {
          publicPart.featureFlagList = featureFlagList;
        }

        const result = !IS_SERVER
          ? publicPart
          : defaultsDeep(
              {},
              locale ? AzureConfigurator.serverCache?.[locale] : {},
              AzureConfigurator.serverCache?.default,
              publicPart,
            );

        return isLocal() ? defaultsDeep({}, localOverrides, result) : result;
      },
    );

  static getConfig(
    locale = AZURE_CONFIG_DEFAULT_LOCALE,
  ): Optional<AllPossibleConfigData> {
    const lowLocale = locale.toLowerCase();
    const data = AzureConfigurator.getAppConfigMemo(lowLocale)()();
    return isLocal()
      ? defaultsDeep(
          {},
          {
            ...AzureConfigurator.mockedConfigValues,
            featureFlagList: AzureConfigurator.mockedFlagValues,
          },
          data,
        )
      : data;
  }

  static getFeatureFlags(
    locale = AZURE_CONFIG_DEFAULT_LOCALE,
  ): Optional<FeatureFlagList> {
    const lowLocale = locale.toLowerCase();
    const data = AzureConfigurator.getFeatureFlagsMemo(lowLocale)()();
    return isLocal() ? defaults({}, this.mockedFlagValues, data) : data;
  }

  static async init(client?: AzureConfigurationClientInterface): Promise<void> {
    if (AzureConfigurator.initPromise) {
      return AzureConfigurator.initPromise;
    }

    AzureConfigurator.initPromise = AzureConfigurator.instance.initInstance(client);

    return AzureConfigurator.initPromise;
  }

  private static clearCache(): void {
    const config = getConfig();
    delete config?.publicRuntimeConfig?.azureConfig;
    delete config?.serverRuntimeConfig?.azureConfig;
    setConfig(config);
  }

  static destruct(): void {
    if (IS_SERVER) {
      AzureConfigurator.instance.destructInstance();
    }
    AzureConfigurator.clearCache();
  }

  private client: Nullable<AzureConfigurationClientInterface> = null;
  private updateCycle: Nullable<ReturnType<typeof setInterval>> = null;

  private async initInstance(client?: AzureConfigurationClientInterface) {
    const LOGGER_CONTEXT_LOCAL = `${LOGGER_CONTEXT}:initInstance`;
    AzureConfigurator.destruct();

    if (IS_SERVER) {
      if (client) {
        this.client = client;
      } else {
        const connectionString = getEnvironmentVariable(
          EnvVars.AZURE_CONFIGURATION_CONNECTION_STRING,
        );

        if (!connectionString) {
          const error = new Error(
            `${LOGGER_CONTEXT_LOCAL}: connection string for Azure AppConfigurationClient is missing!`,
          );

          throw error;
        }

        this.client = new AppConfigurationClient(connectionString);
      }
    }

    if (IS_SERVER) {
      await this.startUpdateCycle();
    }
  }

  private async getFeatureFlagsData(): Promise<Dictionary<FeatureFlagList>> {
    const result: Dictionary<FeatureFlagList> = {};
    if (!IS_SERVER || !this.client) {
      return result;
    }

    const featureFlagsResult = this.client.listConfigurationSettings({
      keyFilter: `${featureFlagPrefix}/*`,
    });

    for await (const setting of featureFlagsResult as AsyncIterableIterator<ConfigurationSetting>) {
      const { key, value, label } = parseFeatureFlag(setting);
      const keyWithoutPrefix = key.replace(featureFlagRegExp, "");
      const receivedLocale = label ?? AZURE_CONFIG_DEFAULT_LOCALE;
      const path = keyWithoutPrefix.split("/");
      set(result, [receivedLocale, ...path], {
        ...get(result, path),
        ...value,
      });
    }
    return result;
  }

  private async getWebAppConfigVariablesData(): Promise<
    Dictionary<Protected<ServerOnlyConfig, ClientConfig>>
  > {
    const result: Dictionary<Protected<ServerOnlyConfig, ClientConfig>> = {};
    if (!IS_SERVER || !this.client) {
      return result;
    }

    const requestResult = this.client.listConfigurationSettings({
      keyFilter: `${webAppPrefix}/*`,
    });

    for await (const setting of requestResult as AsyncIterableIterator<ConfigurationSetting>) {
      const { key, value, label } = setting;
      const receivedLocale = label || AZURE_CONFIG_DEFAULT_LOCALE;
      const keyWithoutPrefix = key.replace(webAppRegExp, "");

      let actualValue = value;
      let privacy = "server";
      let path = [];

      if (
        keyWithoutPrefix.startsWith(clientSidePrefix) ||
        keyWithoutPrefix.startsWith(serverSidePrefix)
      ) {
        const [privacyKey, ...pathArray] = keyWithoutPrefix.split("/");
        privacy = privacyKey;
        path = pathArray;
      } else {
        path = keyWithoutPrefix.split("/");
      }

      if (has(getOverridesForLocalDevelopment(), path)) {
        actualValue = actualValue || undefined;
      }
      set(result, [receivedLocale, privacy, ...path], actualValue);
    }

    return result;
  }

  private async updateData(): Promise<unknown> {
    if (!IS_SERVER || !this.client) {
      return;
    }

    const currentTimestamp = Date.now();
    const resultList = await Promise.allSettled([
      this.getFeatureFlagsData(),
      this.getWebAppConfigVariablesData(),
    ]);
    const [featureFlagsResult, webAppConfigResult] = resultList.map((item) => {
      return item.status === "fulfilled" ? item.value : undefined;
    }) as [
      Optional<Dictionary<FeatureFlagList>>,
      Optional<Protected<Dictionary<ServerOnlyConfig>, Dictionary<ClientConfig>>>,
    ];

    const isFirstRequest = !AzureConfigurator.isReceived;

    const config = getConfig();

    forEach(webAppConfigResult, (value, locale) => {
      set(config, ["publicRuntimeConfig", "azureConfig", locale], value?.client);
      set(config, ["serverRuntimeConfig", "azureConfig", locale], value?.server);
    });

    forEach(featureFlagsResult, (value, locale) => {
      set(
        config,
        ["publicRuntimeConfig", "azureConfig", locale, "featureFlagList"],
        value,
      );
    });

    set(
      config,
      ["publicRuntimeConfig", "azureConfig", "timestamp"],
      currentTimestamp,
    );

    setConfig(config);
    if (isFirstRequest) {
      this.emit(AzureConfiguratorEvent.INIT);
    } else {
      this.emit(AzureConfiguratorEvent.UPDATE);
    }
    this.emit(AzureConfiguratorEvent.SET);
    return config;
  }

  private stopUpdateCycle(): void {
    if (this.updateCycle !== null) {
      clearTimeout(this.updateCycle);
      this.updateCycle = null;
    }
  }

  private async startUpdateCycle(): Promise<void> {
    if (this.client) {
      const iteration = async () => {
        await this.updateData().finally(() => {
          if (IS_SERVER) {
            this.updateCycle = setTimeout(iteration, AzureConfigurator.configTtl);
          }
        });
      };
      await iteration();
    }
  }

  private destructInstance(): void {
    if (IS_SERVER) {
      this.stopUpdateCycle();
      this.client = null;
    }
  }
}

if (IS_SERVER && !AzureConfigurator.isInitialized) {
  AzureConfigurator.init();
}
